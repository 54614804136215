import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { Event } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const selecteventstate: MemoizedSelector<object, State> =
  createFeatureSelector<State>('eventsModalAttacher');

export const selectAllEventsItems: (state: object) => Event[] =
  featureAdapter.getSelectors(selecteventstate).selectAll;

export const selectEventById = (id: string) =>
  createSelector(selectAllEventsItems, (events: Event[]) => {
    if (events) {
      return events.find((p) => p.id === +id);
    } else {
      return null;
    }
  });

export const selectEventsError: MemoizedSelector<object, any> = createSelector(
  selecteventstate,
  getError,
);

export const selectEventsIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selecteventstate, getIsLoading);
