import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { EventsModalAttacherStoreEffects } from './effects';
import { eventsModalAttacherReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('eventsModalAttacher', eventsModalAttacherReducer),
    EffectsModule.forFeature([EventsModalAttacherStoreEffects]),
  ],
})
export class EventsModalAttacherStoreModule {}
