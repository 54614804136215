import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Event } from '../../models';

export const featureAdapter: EntityAdapter<Event> = createEntityAdapter<Event>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<Event> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});
