import { createAction, props } from '@ngrx/store';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { CreateEventRequest, Event, UpdateEventRequest } from '../../models';
import { LoadEventRequest } from '../../models/requests/load-event-request';

export const loadRequest = createAction(
  '[Events Modal Attacher] Load Request',
  props<{ request: LoadEventRequest }>(),
);

export const loadSuccess = createAction(
  '[Events Modal Attacher] Load Success',
  props<{ data: Event[] }>(),
);

export const loadFailure = createAction(
  '[Events Modal Attacher] Load Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Events Modal Attacher] Create Request',
  props<{
    propertyId: number;
    request: CreateEventRequest;
    onSuccess?: (response: IResponseSuccess) => void;
    onFailure?: () => void;
  }>(),
);

export const createSuccess = createAction(
  '[Events Modal Attacher] Create Success',
  props<{ data: Event }>(),
);

export const createFailure = createAction(
  '[Events Modal Attacher] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Events Modal Attacher] Update Request',
  props<{ propertyId: number; eventId: number; request: UpdateEventRequest }>(),
);

export const updateSuccess = createAction(
  '[Events Modal Attacher] Update Success',
  props<{ data: Event }>(),
);

export const updateFailure = createAction(
  '[Events Modal Attacher] Update Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Events Modal Attacher] Delete Request',
  props<{ propertyId: number; eventId: number }>(),
);

export const deleteSuccess = createAction(
  '[Events Modal Attacher] Delete Success',
  props<{ eventId: number }>(),
);

export const deleteFailure = createAction(
  '[Events Modal Attacher] Delete Failure',
  props<{ error: any }>(),
);

export const reseState = createAction('[Events Modal Attacher] Reset State');
